@media only screen and (max-width: 1250px) {
    main, 
    .container {
        width: 740px;
        height: auto !important;
        &.landscape {
            width: auto !important;
        }        
    }
    main header {
       //transform: scale(0.8) translateX(-93px);
    }
    #titlecontainer {
    margin: 0;
    }
    .guide {
        height: 68px;
    }

    main.portrait #container_guides,
    main.portrait .guide {
        width: 560px;
    }
    main.landscape #container_guides,
    main.landscape .guide { 
    width: 750px;
    }

    .trace {
        top: -7px;
        font-size: 90px;
    }

    .midline {
        border-width: 1px;
        top: 33px;
    }

    .lowercase.guide {
        .midline {
            border-width: 1px;
            top: 33px;
        }
        .trace {
            top: -12px;
            font-size: 101px;
        }
    }

    .button {
        margin: 60px 0;
    }

    #stickerleft,
    #stickerright {
        position: absolute;
        transform: scale(.5);
        top: 0;
    }
    #stickerleft { left: -7%; }
    #stickerright { right: -7%; }
}
  



@media only screen and (max-width: 844px) {
    #info {
        top: 28px;
        right: 0;
        width: 90%;
        height: 100vh;
        padding-bottom: 150px;
        overflow: scroll;
        box-sizing: border-box;
        opacity: 1;
        &.show {
            right: 5%;
        }
    }
}




@media only screen and (max-width: 767px) {
    h1 {
        margin: 0;
    }
    main, 
    .container {
      width: 360px;
      height: auto !important;
      &.landscape {
        width: auto !important;
      }
    }

    main {
        padding-bottom: 0;
    }

    main header {
        padding-top: 20px;
    }

    main.portrait #stickerleft,
    main.portrait #stickerright { 
        transform: scale(.30);
        top: -20px;
    }
    #stickerleft { left: -80px }
    #stickerright { right: -80px }
    
    #curvedtitle { transform: scale(.5)}


    .credits {
        padding-bottom: 60px;
    }
    header#worksheetheader {
        //transform: scale(.4) translate(-290px,-100px);
    }   
    
    // #textinput {
    //     position: absolute;
    //     bottom: -230px;
    //     margin-bottom: 0;
    //     width: 650px;
    //     padding: 50px;
    //     font-size: 60px;
    //     border-radius: 100px;
    //     text-align: center;
    //     border-width: 18px;
    // }
    // small {
    //     bottom: -280px;
    //     width: 100%;
    //     font-size: 20px;
    //     position: absolute;
    // }
    #container_guides{
        margin-top: 0;
    }
    .guide {
        height: 42px;
    }
 
    main.portrait #container_guides,
    main.portrait .guide {
       width: 310px;
    }
    main.landscape #container_guides,
    main.landscape .guide { 
      width: 420px;
    }     
    .trace {
        top: -2px;
        font-size: 50px;
    }
    .midline {
        border-width: 1px;
        top: 20px;
    }

    .lowercase.guide {
        .midline {
            border-width: 1px;
            top: 20px;
        }
        .trace {
            top: -6px;
            font-size: 59px;
        }
    }

    #options {
        width: 100%;
    }
    .button {
        margin: 40px 0;
    }
    #options,
    #options h3 {
        border-top-left-radius: 0;
    }    
  }

